import _taggedTemplateLiteral from"/codebuild/output/src3574/src/s3/00/node_modules/@babel/runtime/helpers/esm/taggedTemplateLiteral.js";var _templateObject,_templateObject2;import{useTransition}from"react";import styled from"@emotion/styled";import fuzzysort from"fuzzysort";import{Box,MenuItem,TextField}from"@mui/material";import{OrderStatuses}from"common/enums/OrderStatusEnum";import{formatName,isFalsy}from"common/helpers/helpers";import{getRoleLabel,StaffRoles}from"common/enums/RolesEnum";import OrderTypeEnum from"common/enums/OrderTypeEnum";import{sanitizeLowercaseString}from"../../../helpers/helpers";import DebouncedInput from"../../../components/Input/DebouncedInput";import{blue}from"common/styling/colors";import{jsx as _jsx}from"@emotion/react/jsx-runtime";const StyledInput=styled(DebouncedInput)(_templateObject||(_templateObject=_taggedTemplateLiteral(["\n  background: ",";\n"])),blue[50]);const FilterContainer=styled.div(_templateObject2||(_templateObject2=_taggedTemplateLiteral(["\n  margin-bottom: 24px;\n"])));export const StyledSelect=_ref=>{let{options,onChange,value,placeholder,width}=_ref;return _jsx(Box,{width:width,children:_jsx(TextField,{select:true,fullWidth:!isFalsy(width),sx:width&&{width:width},value:(value===null||value===void 0?void 0:value.value)||"",label:placeholder,"data-testid":placeholder,id:placeholder,defaultValue:"",onChange:event=>{const foundItem=options.find(item=>item.value===event.target.value);onChange(foundItem);}// There is a console warning being thrown because of how MUI is implemented
// https://github.com/mui/material-ui/issues/38869#issuecomment-1792192008
// This will likely be fixed in MUI v6
,slotProps:{input:{id:placeholder},inputLabel:{htmlFor:placeholder}},children:options.map(_ref2=>{let{value,label,disabled}=_ref2;if(disabled)return _jsx(MenuItem,{disabled:true,style:{marginLeft:"-8px"},value:value,"data-testid":label,children:label},value);return _jsx(MenuItem,{value:value,"data-testid":label,children:label},value);})})});};export const defaultFilterFn=(row,columnId,value)=>{const rowValue=row.getValue(columnId);// @ts-ignore
const includesValue=rowValue.includes(value);return includesValue;};export const orderStatusFilterFn=(row,columnId,value)=>{const rowValue=row.getValue(columnId);return rowValue===value;};export const orderTypeFilterFn=(row,columnId,value)=>{const rowValue=row.getValue(columnId);return rowValue===value;};export const fuzzyFilterFn=(row,columnId,value)=>{const rowValue=row.getValue(columnId);let multipleWords=false;let searchTermArray=[];if(value!==null&&value!==void 0&&value.includes(" ")){multipleWords=true;const removedExtraSpaces=value===null||value===void 0?void 0:value.replace(/\s+/g," ").trim();searchTermArray=removedExtraSpaces===null||removedExtraSpaces===void 0?void 0:removedExtraSpaces.split(" ");}if(multipleWords){let isGoodMatch=true;if(searchTermArray){searchTermArray.forEach(word=>{const result=fuzzysort.single(word,rowValue);// if any of the search terms are not a good match, then the whole thing is not a good match
if(!result||(result===null||result===void 0?void 0:result.score)<=-10000){isGoodMatch=false;}});}return isGoodMatch;}else{const result=fuzzysort.single(sanitizeLowercaseString(value),rowValue);return Boolean((rowValue===null||rowValue===void 0?void 0:rowValue.includes(sanitizeLowercaseString(value)))||(result===null||result===void 0?void 0:result.score)&&result.score>-10000);}};export function getFilterFns(columns){const finalFilterFns={};columns.forEach(column=>{const{filterEnabled,name,filterFn}=column;if(filterEnabled){switch(filterFn){case"fuzzy":{finalFilterFns[name]=fuzzyFilterFn;break;}case"orderStatus":{finalFilterFns[name]=orderStatusFilterFn;break;}case"orderType":{finalFilterFns[name]=orderTypeFilterFn;break;}default:{finalFilterFns[name]=defaultFilterFn;break;}}}});return finalFilterFns;}const ALL_OPTION={value:"All",label:"All"};function Filter(_ref3){var _orderTypeOptions$fin,_orderStatusOptions$f;let{column,filterKeys}=_ref3;const{id}=column;const columnFilterValue=column.getFilterValue();const[isPending,startTransition]=useTransition();if(!filterKeys.includes(id))return null;switch(id){case"roles":{const options=[...StaffRoles].sort((a,b)=>a.localeCompare(b)).map(role=>{return{value:role,label:getRoleLabel(role)};});// add as first element
// @ts-ignore
options.unshift(ALL_OPTION);const selectedOption=options.find(option=>option.value===columnFilterValue);return _jsx(FilterContainer,{children:_jsx(StyledSelect,{width:"200px",options:options,onChange:_ref4=>{let{value}=_ref4;startTransition(()=>{if(value==="All")column.setFilterValue(undefined);else column.setFilterValue(value);});},value:selectedOption,placeholder:"Filter by ".concat(column.id)})});}case"orderType":const orderTypeOptions=Object.keys(OrderTypeEnum).map(orderType=>{return{value:orderType,label:formatName(orderType)};});orderTypeOptions.unshift(ALL_OPTION);const selectedOrderTypeOption=(_orderTypeOptions$fin=orderTypeOptions.find(option=>{return option.value===columnFilterValue;}))!==null&&_orderTypeOptions$fin!==void 0?_orderTypeOptions$fin:ALL_OPTION;return _jsx(StyledSelect,{width:"200px",options:orderTypeOptions,onChange:_ref5=>{let{value}=_ref5;startTransition(()=>{if(value==="All")column.setFilterValue(undefined);else column.setFilterValue(value);});},value:selectedOrderTypeOption,placeholder:"Filter by Type"});case"orderStatus":const orderStatusOptions=OrderStatuses.map(status=>{return{value:status,label:formatName(status)};});// add as first element
orderStatusOptions.unshift(ALL_OPTION);let selectedOrderStatusOption=(_orderStatusOptions$f=orderStatusOptions.find(option=>option.value===columnFilterValue))!==null&&_orderStatusOptions$f!==void 0?_orderStatusOptions$f:ALL_OPTION;return _jsx(FilterContainer,{children:_jsx(StyledSelect,{width:"200px",options:orderStatusOptions,onChange:_ref6=>{let{value}=_ref6;startTransition(()=>{if(value==="All")column.setFilterValue(undefined);else column.setFilterValue(value);});},value:selectedOrderStatusOption,placeholder:"Filter by Status"})});case"name":return _jsx(FilterContainer,{children:_jsx(StyledInput,{debounceTimeout:300,value:columnFilterValue!==null&&columnFilterValue!==void 0?columnFilterValue:"",onChange:event=>startTransition(()=>{// @ts-ignore
column.setFilterValue(event.target.value);}),type:"text",id:"search",placeholder:"Search..."})});case"fullname":return _jsx(FilterContainer,{children:_jsx(StyledInput,{debounceTimeout:300,value:columnFilterValue!==null&&columnFilterValue!==void 0?columnFilterValue:"",onChange:event=>startTransition(()=>{// @ts-ignore
column.setFilterValue(event.target.value);}),type:"text",id:"search",placeholder:"Search..."})});default:return null;}}export function getFilters(tableHeaders,filterKeys){const filterComponents=[];filterKeys.forEach(key=>{const header=tableHeaders.find(header=>header.id===key);filterComponents.push(_jsx(Filter,{column:header.column,filterKeys:filterKeys},header.id));});return filterComponents;}